import { gql } from 'apollo-angular';
import { Injectable } from '@angular/core';
import * as Apollo from 'apollo-angular';
export type Maybe<T> = T | null;
export type InputMaybe<T> = Maybe<T>;
export type Exact<T extends { [key: string]: unknown }> = { [K in keyof T]: T[K] };
export type MakeOptional<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]?: Maybe<T[SubKey]> };
export type MakeMaybe<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]: Maybe<T[SubKey]> };
export type MakeEmpty<T extends { [key: string]: unknown }, K extends keyof T> = { [_ in K]?: never };
export type Incremental<T> = T | { [P in keyof T]?: P extends ' $fragmentName' | '__typename' ? T[P] : never };
/** All built-in and custom scalars, mapped to their actual values */
export type Scalars = {
  ID: { input: string; output: string; }
  String: { input: string; output: string; }
  Boolean: { input: boolean; output: boolean; }
  Int: { input: number; output: number; }
  Float: { input: number; output: number; }
  /** A date-time string at UTC, such as 2019-12-03T09:54:33Z, compliant with the date-time format. */
  DateTime: { input: string; output: string; }
  /** The `Upload` scalar type represents a file upload. */
  Upload: { input: any; output: any; }
};

export enum AbsenceEnum {
  ACCIDENT = 'ACCIDENT',
  BIRTH_OR_WEDDING_OF_CHILD = 'BIRTH_OR_WEDDING_OF_CHILD',
  CARING_FOR_SICK_CHILD = 'CARING_FOR_SICK_CHILD',
  DEATH_IN_HOUSEHOLD = 'DEATH_IN_HOUSEHOLD',
  DEATH_OF_CLOSE_RELATIVE = 'DEATH_OF_CLOSE_RELATIVE',
  FULFILLMENT_OF_LEGAL_OBLIGATIONS = 'FULFILLMENT_OF_LEGAL_OBLIGATIONS',
  MATERNITY_LEAVE = 'MATERNITY_LEAVE',
  MILITARY_OR_CIVIL_SERVICE = 'MILITARY_OR_CIVIL_SERVICE',
  MILITARY_RECRUITMENT_OR_INSPECTION = 'MILITARY_RECRUITMENT_OR_INSPECTION',
  RESIDENCE_MOVING = 'RESIDENCE_MOVING',
  SICKNESS = 'SICKNESS',
  VACATION_PAID = 'VACATION_PAID',
  VACATION_UNPAID = 'VACATION_UNPAID',
  WEDDING = 'WEDDING'
}

export type Address = {
  country: Scalars['String']['output'];
  location: Scalars['String']['output'];
  street: Scalars['String']['output'];
  zip: Scalars['String']['output'];
};

export type AddressUpdateInput = {
  country?: InputMaybe<Scalars['String']['input']>;
  location?: InputMaybe<Scalars['String']['input']>;
  street?: InputMaybe<Scalars['String']['input']>;
  zip?: InputMaybe<Scalars['String']['input']>;
};

export type BankingInformation = {
  iban: Scalars['String']['output'];
  recipient: Maybe<BankingRecipient>;
};

export type BankingInformationInput = {
  iban: Scalars['String']['input'];
  recipient?: InputMaybe<BankingRecipientInput>;
};

export type BankingRecipient = {
  address: Address;
  firstName: Scalars['String']['output'];
  lastName: Scalars['String']['output'];
};

export type BankingRecipientInput = {
  address?: InputMaybe<AddressUpdateInput>;
  firstName?: InputMaybe<Scalars['String']['input']>;
  lastName?: InputMaybe<Scalars['String']['input']>;
};

export type CheckUserVerificationInput = {
  dateOfBirth: Scalars['String']['input'];
  personalNumber: Scalars['Float']['input'];
};

export type CompanyContactInformation = {
  address: Address;
  email: Scalars['String']['output'];
  name: Scalars['String']['output'];
  phone: Scalars['String']['output'];
  shortName: Maybe<Scalars['String']['output']>;
};

export type ConsultantBranch = {
  location: Scalars['String']['output'];
  mailingAddress: Scalars['String']['output'];
  name: Scalars['String']['output'];
  slug: Scalars['String']['output'];
  street: Scalars['String']['output'];
  zip: Scalars['String']['output'];
};

export type ConsultantContact = {
  branch: ConsultantBranch;
  email: Scalars['String']['output'];
  name: Scalars['String']['output'];
  phone: Maybe<Scalars['String']['output']>;
  position: Scalars['String']['output'];
};

export enum CurrencyEnum {
  CHF = 'CHF',
  EUR = 'EUR',
  USD = 'USD'
}

export type Day = {
  absenceUUID: Maybe<Scalars['String']['output']>;
  approvedAt: Maybe<Scalars['DateTime']['output']>;
  approvedBy: Maybe<User>;
  date: Scalars['String']['output'];
  expenseUUID: Maybe<Scalars['String']['output']>;
  hoursEntries: Array<HoursEntry>;
  reportUUID: Maybe<Scalars['String']['output']>;
  status: DayStatusEnum;
  uniProHoursReportRUID: Maybe<Scalars['Float']['output']>;
};


export type DayHoursEntriesArgs = {
  uuid?: Scalars['String']['input'];
};

export type DayEntryReference = {
  dayDate: Scalars['String']['input'];
  entryId: Scalars['String']['input'];
};

export type DayEntryUploadInput = {
  documentType: DocumentTypeEnum;
  employmentUuid: Scalars['String']['input'];
  file: Scalars['Upload']['input'];
  linkedEntry?: InputMaybe<DayEntryReference>;
  uuid: Scalars['String']['input'];
};

export type DayInput = {
  date: Scalars['String']['input'];
  hoursEntries?: InputMaybe<Array<HoursEntryInput>>;
  status: DayStatusEnum;
};

export enum DayStatusEnum {
  APPROVED = 'APPROVED',
  HAS_REPORT = 'HAS_REPORT',
  OPEN = 'OPEN'
}

export type DeleteDayEntryUploadInput = {
  employmentUuid: Scalars['String']['input'];
  linkedEntry: DayEntryReference;
  uuid: Scalars['String']['input'];
};

export enum DocumentStatusEnum {
  PINNED = 'PINNED',
  READ = 'READ',
  UNREAD = 'UNREAD'
}

export enum DocumentTypeEnum {
  AHV = 'AHV',
  CORRESPONDENCE = 'CORRESPONDENCE',
  CRIMINAL_RECORD = 'CRIMINAL_RECORD',
  CV = 'CV',
  DEPT_ENFORCEMENT_REGISTER_REPORT = 'DEPT_ENFORCEMENT_REGISTER_REPORT',
  EMPLOYER_STATEMENT = 'EMPLOYER_STATEMENT',
  FAMILY_ALLOWANCES = 'FAMILY_ALLOWANCES',
  FOREIGNER_PERMIT = 'FOREIGNER_PERMIT',
  HOURS_REPORT = 'HOURS_REPORT',
  INVOICE = 'INVOICE',
  MEDICAL_CERTIFICATE = 'MEDICAL_CERTIFICATE',
  OTHER = 'OTHER',
  PAY_SLIP = 'PAY_SLIP',
  PROFILE_IMAGE = 'PROFILE_IMAGE',
  RECEIPT = 'RECEIPT',
  WAGE_STATEMENT = 'WAGE_STATEMENT',
  WORK_PERMIT = 'WORK_PERMIT',
  ZWISCHENVERDIENST = 'ZWISCHENVERDIENST'
}

export type DurationEntry = Entry & {
  createdAt: Scalars['DateTime']['output'];
  createdBy: PersonalContactInformation;
  deletedAt: Maybe<Scalars['DateTime']['output']>;
  document: Maybe<StoredDocument>;
  endDate: Scalars['String']['output'];
  kind: Maybe<AbsenceEnum>;
  note: Maybe<Scalars['String']['output']>;
  previousVersion: Maybe<DurationEntryPreviousVersion>;
  startDate: Scalars['String']['output'];
  type: DurationEntryTypeEnum;
  uuid: Scalars['String']['output'];
};

export type DurationEntryInput = {
  delete?: InputMaybe<Scalars['Boolean']['input']>;
  deleteDocument?: InputMaybe<Scalars['Boolean']['input']>;
  documentUUID?: InputMaybe<Scalars['String']['input']>;
  employmentId: Scalars['String']['input'];
  /** yyyy-mm-dd */
  endDate: Scalars['String']['input'];
  file?: InputMaybe<FileUploadInput>;
  kind?: InputMaybe<AbsenceEnum>;
  note?: InputMaybe<Scalars['String']['input']>;
  previousVersion?: InputMaybe<DurationEntryPreviousVersionInput>;
  /** yyyy-mm-dd */
  startDate: Scalars['String']['input'];
  type: DurationEntryTypeEnum;
  uuid: Scalars['String']['input'];
};

export type DurationEntryPreviousVersion = Entry & {
  createdAt: Scalars['DateTime']['output'];
  createdBy: PersonalContactInformation;
  deletedAt: Maybe<Scalars['DateTime']['output']>;
  document: Maybe<StoredDocument>;
  endDate: Scalars['String']['output'];
  kind: Maybe<AbsenceEnum>;
  note: Maybe<Scalars['String']['output']>;
  startDate: Scalars['String']['output'];
  type: DurationEntryTypeEnum;
  uuid: Scalars['String']['output'];
};

export type DurationEntryPreviousVersionInput = {
  createdAt?: InputMaybe<Scalars['String']['input']>;
  createdBy?: InputMaybe<Scalars['String']['input']>;
  delete?: InputMaybe<Scalars['Boolean']['input']>;
  document?: InputMaybe<Scalars['String']['input']>;
  documentUUID?: InputMaybe<Scalars['String']['input']>;
  /** yyyy-mm-dd */
  endDate: Scalars['String']['input'];
  file?: InputMaybe<FileUploadInput>;
  kind?: InputMaybe<AbsenceEnum>;
  note?: InputMaybe<Scalars['String']['input']>;
  /** yyyy-mm-dd */
  startDate: Scalars['String']['input'];
  type: DurationEntryTypeEnum;
  uuid: Scalars['String']['input'];
};

export enum DurationEntryTypeEnum {
  ABSENCE = 'ABSENCE',
  REPORT = 'REPORT'
}

export type Employment = {
  absences: Array<DurationEntry>;
  active: Maybe<Scalars['Boolean']['output']>;
  branch: Maybe<CompanyContactInformation>;
  company: Maybe<UniProCompany>;
  consultant: Maybe<PersonalContactInformation>;
  days: Array<Day>;
  daysToApproveCount: Maybe<Scalars['Float']['output']>;
  documents: Array<StoredDocument>;
  employee: Maybe<PersonalContactInformation>;
  endDate: Maybe<Scalars['String']['output']>;
  expenses: Array<ExpensesEntry>;
  hasDaysToApprove: Maybe<Scalars['Boolean']['output']>;
  isTimeTrackingEnabled: Maybe<Scalars['Boolean']['output']>;
  personalNumber: Scalars['Float']['output'];
  reports: Array<DurationEntry>;
  startDate: Scalars['String']['output'];
  statistics: Maybe<EmploymentStatistics>;
  updatedAt: Maybe<Scalars['DateTime']['output']>;
  uuid: Scalars['String']['output'];
};

export type EmploymentInput = {
  days: Array<DayInput>;
  uuid: Scalars['String']['input'];
};

export type EmploymentStatistics = {
  absences: Scalars['Float']['output'];
  expenses: Array<ValueCurrency>;
  totalHours: Scalars['Float']['output'];
  usedHours: Maybe<Scalars['Float']['output']>;
  vacationDays: Scalars['Float']['output'];
};

export type Entry = {
  createdAt: Scalars['DateTime']['output'];
  createdBy: PersonalContactInformation;
  deletedAt: Maybe<Scalars['DateTime']['output']>;
  document: Maybe<StoredDocument>;
  note: Maybe<Scalars['String']['output']>;
  uuid: Scalars['String']['output'];
};

export type ExpenseEntry = Entry & {
  createdAt: Scalars['DateTime']['output'];
  createdBy: PersonalContactInformation;
  deletedAt: Maybe<Scalars['DateTime']['output']>;
  document: Maybe<StoredDocument>;
  expense: ValueCurrency;
  note: Maybe<Scalars['String']['output']>;
  previousVersion: Maybe<ExpenseEntryPreviousVersion>;
  uuid: Scalars['String']['output'];
};

export type ExpenseEntryInput = {
  dayDate: Scalars['String']['input'];
  employmentId: Scalars['String']['input'];
  expenses: Array<ExpensesInput>;
  uuid: Scalars['String']['input'];
};

export type ExpenseEntryPreviousVersion = Entry & {
  createdAt: Scalars['DateTime']['output'];
  createdBy: PersonalContactInformation;
  deletedAt: Maybe<Scalars['DateTime']['output']>;
  document: Maybe<StoredDocument>;
  expense: ValueCurrency;
  note: Maybe<Scalars['String']['output']>;
  uuid: Scalars['String']['output'];
};

export enum ExpenseEnum {
  ACCOMMODATION = 'ACCOMMODATION',
  MEAL = 'MEAL',
  OTHER = 'OTHER',
  RIDE_COST = 'RIDE_COST'
}

export type ExpensesEntry = Entry & {
  createdAt: Scalars['DateTime']['output'];
  createdBy: PersonalContactInformation;
  deletedAt: Maybe<Scalars['DateTime']['output']>;
  document: Maybe<StoredDocument>;
  expenses: Array<ExpenseEntry>;
  note: Maybe<Scalars['String']['output']>;
  uuid: Scalars['String']['output'];
};

export type ExpensesInput = {
  delete?: InputMaybe<Scalars['Boolean']['input']>;
  documentUUID?: InputMaybe<Scalars['String']['input']>;
  expense: ValueCurrencyInput;
  file?: InputMaybe<FileUploadInput>;
  kind?: InputMaybe<ExpenseEnum>;
  note?: InputMaybe<Scalars['String']['input']>;
  previousVersion?: InputMaybe<PreviousVersionInput>;
  uuid: Scalars['String']['input'];
};

export type FileUploadInput = {
  documentType: DocumentTypeEnum;
  file: Scalars['Upload']['input'];
  uuid: Scalars['String']['input'];
};

export enum GlobalRoleEnum {
  APPLICANT = 'APPLICANT',
  SUPER_ADMIN = 'SUPER_ADMIN'
}

export type HoursEntry = Entry & {
  createdAt: Scalars['DateTime']['output'];
  createdBy: PersonalContactInformation;
  deletedAt: Maybe<Scalars['DateTime']['output']>;
  document: Maybe<StoredDocument>;
  duration: Scalars['Float']['output'];
  from: Maybe<Scalars['Float']['output']>;
  note: Maybe<Scalars['String']['output']>;
  pause: Maybe<Scalars['Float']['output']>;
  previousVersions: Array<HoursEntryPreviousVersion>;
  to: Maybe<Scalars['Float']['output']>;
  uuid: Scalars['String']['output'];
};

export type HoursEntryInput = {
  delete?: InputMaybe<Scalars['Boolean']['input']>;
  documentUUID?: InputMaybe<Scalars['String']['input']>;
  duration: Scalars['Float']['input'];
  from?: InputMaybe<Scalars['Float']['input']>;
  note?: InputMaybe<Scalars['String']['input']>;
  pause?: InputMaybe<Scalars['Float']['input']>;
  previousVersions?: InputMaybe<Array<PreviousVersionInput>>;
  to?: InputMaybe<Scalars['Float']['input']>;
  uuid: Scalars['String']['input'];
};

export type HoursEntryPreviousVersion = Entry & {
  createdAt: Scalars['DateTime']['output'];
  createdBy: PersonalContactInformation;
  deletedAt: Maybe<Scalars['DateTime']['output']>;
  document: Maybe<StoredDocument>;
  duration: Scalars['Float']['output'];
  from: Maybe<Scalars['Float']['output']>;
  note: Maybe<Scalars['String']['output']>;
  pause: Maybe<Scalars['Float']['output']>;
  to: Maybe<Scalars['Float']['output']>;
  uuid: Scalars['String']['output'];
};

export type Mutation = {
  createCompanyUser: Scalars['String']['output'];
  preVerificationUserCheck: Scalars['Boolean']['output'];
  removeEntryDocument: Scalars['Boolean']['output'];
  removeProfileImage: Scalars['Boolean']['output'];
  removeUserDocument: Scalars['Boolean']['output'];
  resendVerificationEmail: Scalars['Boolean']['output'];
  sendVerificationSms: VerifySmsResponse;
  updateDurationEntry: Scalars['Boolean']['output'];
  updateEmployment: Employment;
  updateExpenseEntry: Scalars['Boolean']['output'];
  updateUser: User;
  uploadEntryDocument: StoredDocument;
  uploadProfileImage: Scalars['Boolean']['output'];
  uploadUserDocument: Scalars['Boolean']['output'];
  verifyUser: Scalars['Boolean']['output'];
};


export type MutationCreateCompanyUserArgs = {
  data: UserInput;
};


export type MutationPreVerificationUserCheckArgs = {
  CheckUserVerificationInput: CheckUserVerificationInput;
};


export type MutationRemoveEntryDocumentArgs = {
  deleteDayEntryUploadInput: DeleteDayEntryUploadInput;
};


export type MutationRemoveProfileImageArgs = {
  uuid: Scalars['String']['input'];
};


export type MutationRemoveUserDocumentArgs = {
  uuid: Scalars['String']['input'];
};


export type MutationSendVerificationSmsArgs = {
  CheckUserVerificationInput: CheckUserVerificationInput;
};


export type MutationUpdateDurationEntryArgs = {
  data: DurationEntryInput;
};


export type MutationUpdateEmploymentArgs = {
  data: EmploymentInput;
};


export type MutationUpdateExpenseEntryArgs = {
  data: ExpenseEntryInput;
};


export type MutationUpdateUserArgs = {
  UserInput: UserInput;
};


export type MutationUploadEntryDocumentArgs = {
  dayEntryUploadInput: DayEntryUploadInput;
};


export type MutationUploadProfileImageArgs = {
  StoredDocumentInput: StoredDocumentInput;
};


export type MutationUploadUserDocumentArgs = {
  StoredDocumentInput: StoredDocumentInput;
};


export type MutationVerifyUserArgs = {
  VerifyUserInput: VerifyUserInput;
};

export type NotificationSettings = {
  disableHourOverdueNotifications: Maybe<Scalars['Boolean']['output']>;
  disableHoursTransferredNotifications: Maybe<Scalars['Boolean']['output']>;
};

export type NotificationSettingsInput = {
  disableHourOverdueNotifications?: InputMaybe<Scalars['Boolean']['input']>;
  disableHoursTransferredNotifications?: InputMaybe<Scalars['Boolean']['input']>;
};

export type OrganisationUnit = {
  customerUuid: Scalars['String']['output'];
  globalRoles: Maybe<Array<GlobalRoleEnum>>;
};

export type PersonalContactInformation = {
  address: Maybe<Address>;
  bankingInformation: Maybe<BankingInformation>;
  email: Scalars['String']['output'];
  firstName: Scalars['String']['output'];
  image: Maybe<StoredDocument>;
  language: Maybe<SupportedLanguage>;
  lastName: Scalars['String']['output'];
  originalEmail: Maybe<Scalars['String']['output']>;
  phone: Maybe<Scalars['String']['output']>;
  position: Maybe<Scalars['String']['output']>;
  sex: SexEnum;
  storedDocuments: Maybe<Array<StoredDocument>>;
  translatedPayrollLanguage: Maybe<Scalars['String']['output']>;
};

export type PersonalContactInformationInput = {
  address?: InputMaybe<AddressUpdateInput>;
  bankingInformation?: InputMaybe<BankingInformationInput>;
  email?: InputMaybe<Scalars['String']['input']>;
  firstName?: InputMaybe<Scalars['String']['input']>;
  image?: InputMaybe<Scalars['String']['input']>;
  language?: InputMaybe<Scalars['String']['input']>;
  lastName?: InputMaybe<Scalars['String']['input']>;
  phone?: InputMaybe<Scalars['String']['input']>;
  sex?: InputMaybe<SexEnum>;
};

export type PreviousVersionInput = {
  uuid: Scalars['String']['input'];
};

export type Query = {
  Employment: Employment;
  Employments: Array<Employment>;
  User: User;
  downloadUserDocument: StoredDocument;
  getHoursExport: Scalars['String']['output'];
  getSamsungBenefit: Scalars['String']['output'];
  getStoredDocument: Maybe<StoredDocument>;
  uniProCompaniesQuery: Array<UniProCompany>;
};


export type QueryEmploymentArgs = {
  uuid: Scalars['String']['input'];
};


export type QueryDownloadUserDocumentArgs = {
  uuid: Scalars['String']['input'];
};


export type QueryGetHoursExportArgs = {
  from: Scalars['DateTime']['input'];
  personalNumbers: Array<Scalars['Float']['input']>;
  to: Scalars['DateTime']['input'];
  types: Array<DayStatusEnum>;
};


export type QueryGetStoredDocumentArgs = {
  uuid: Scalars['String']['input'];
};

export enum SexEnum {
  FEMALE = 'FEMALE',
  MALE = 'MALE',
  OTHER = 'OTHER'
}

export type StoredDocument = {
  _id: Maybe<Scalars['String']['output']>;
  companyId: Maybe<Scalars['Float']['output']>;
  createdAt: Maybe<Scalars['DateTime']['output']>;
  data: Maybe<Scalars['String']['output']>;
  deletedAt: Maybe<Scalars['DateTime']['output']>;
  description: Maybe<Scalars['String']['output']>;
  fileName: Scalars['String']['output'];
  mimeType: Scalars['String']['output'];
  notifiedAt: Maybe<Scalars['DateTime']['output']>;
  personalNumber: Maybe<Scalars['Float']['output']>;
  status: DocumentStatusEnum;
  title: Maybe<Scalars['String']['output']>;
  translatedDocumentId: Maybe<Scalars['String']['output']>;
  type: DocumentTypeEnum;
  uuid: Scalars['String']['output'];
};

export type StoredDocumentInput = {
  companyId?: InputMaybe<Scalars['Float']['input']>;
  data: Scalars['String']['input'];
  description?: InputMaybe<Scalars['String']['input']>;
  fileName: Scalars['String']['input'];
  mimeType: Scalars['String']['input'];
  personalNumber?: InputMaybe<Scalars['Float']['input']>;
  status: DocumentStatusEnum;
  title?: InputMaybe<Scalars['String']['input']>;
  type: DocumentTypeEnum;
  uuid: Scalars['String']['input'];
};

export type Subscription = {
  employmentSubscribe: Employment;
};


export type SubscriptionEmploymentSubscribeArgs = {
  uuid: Scalars['String']['input'];
};

export enum SupportedLanguage {
  DE = 'DE',
  EN = 'EN',
  FR = 'FR',
  IT = 'IT'
}

export type TrackedActionsInput = {
  hasSeenCarifyBenefit?: InputMaybe<Scalars['Boolean']['input']>;
  hasSeenFitpassBenefit?: InputMaybe<Scalars['Boolean']['input']>;
  hasSeenGalaxusBenefit?: InputMaybe<Scalars['Boolean']['input']>;
  hasSeenKieserBenefit?: InputMaybe<Scalars['Boolean']['input']>;
  hasSeenSamsungBenefit?: InputMaybe<Scalars['Boolean']['input']>;
};

export type TrackedActionsModel = {
  hasSeenCarifyBenefit: Maybe<Scalars['Boolean']['output']>;
  hasSeenFitpassBenefit: Maybe<Scalars['Boolean']['output']>;
  hasSeenGalaxusBenefit: Maybe<Scalars['Boolean']['output']>;
  hasSeenKieserBenefit: Maybe<Scalars['Boolean']['output']>;
  hasSeenSamsungBenefit: Maybe<Scalars['Boolean']['output']>;
};

export type UniProCompany = {
  contact: CompanyContactInformation;
  disableTimeTracking: Maybe<Scalars['Boolean']['output']>;
  uuid: Scalars['Float']['output'];
};

export type User = {
  _id: Scalars['String']['output'];
  companyIds: Maybe<Array<Scalars['Float']['output']>>;
  consultants: Maybe<Array<ConsultantContact>>;
  createdAt: Maybe<Scalars['DateTime']['output']>;
  lastSeenVersion: Maybe<Scalars['String']['output']>;
  notificationSettings: Maybe<NotificationSettings>;
  organisationUnit: Maybe<OrganisationUnit>;
  personalNumber: Maybe<Scalars['Float']['output']>;
  profile: PersonalContactInformation;
  responsibleForEmployees: Maybe<Array<Scalars['Float']['output']>>;
  staff: Maybe<Scalars['Boolean']['output']>;
  storedDocuments: Maybe<Array<StoredDocument>>;
  trackedActions: Maybe<TrackedActionsModel>;
  verificationPending: Maybe<Scalars['Boolean']['output']>;
  welcomeEmailPending: Maybe<Scalars['Boolean']['output']>;
};

export type UserInput = {
  companyId?: InputMaybe<Scalars['Float']['input']>;
  lastSeenVersion?: InputMaybe<Scalars['String']['input']>;
  notificationSettings?: InputMaybe<NotificationSettingsInput>;
  organisationId?: InputMaybe<Scalars['Float']['input']>;
  profile?: InputMaybe<PersonalContactInformationInput>;
  responsibleForEmployees?: InputMaybe<Array<Scalars['Float']['input']>>;
  trackedActions?: InputMaybe<TrackedActionsInput>;
};

export type ValueCurrency = {
  currency: CurrencyEnum;
  value: Scalars['Float']['output'];
};

export type ValueCurrencyInput = {
  currency: CurrencyEnum;
  value: Scalars['Float']['input'];
};

export type VerifySmsResponse = {
  delay: Scalars['Float']['output'];
  phone: Scalars['String']['output'];
};

export type VerifyUserInput = {
  dateOfBirth: Scalars['String']['input'];
  language: Scalars['String']['input'];
  personalNumber: Scalars['Float']['input'];
  secret: Scalars['String']['input'];
};

export type AbsenceDurationEntryFragment = { uuid: string, startDate: string, endDate: string, kind: AbsenceEnum | null, note: string | null, createdAt: string, document: StoredDocumentFragment | null, createdBy: { firstName: string, lastName: string, email: string }, previousVersion: { uuid: string, startDate: string, endDate: string, kind: AbsenceEnum | null, note: string | null, createdAt: string, document: StoredDocumentFragment | null, createdBy: { firstName: string, lastName: string, email: string } } | null };

export type CompanyContactInformationFragment = { name: string, address: { street: string, zip: string, location: string, country: string } };

export type ConsultantContactFragment = { name: string, position: string, email: string, phone: string | null, branch: { name: string, mailingAddress: string, street: string, zip: string, location: string, slug: string } };

export type DayFragment = { absenceUUID: string | null, expenseUUID: string | null, reportUUID: string | null, date: string, status: DayStatusEnum, approvedAt: string | null, approvedBy: { profile: { firstName: string, lastName: string } } | null, hoursEntries: Array<HoursEntryFragment> };

export type EmploymentFragment = { uuid: string, startDate: string, endDate: string | null, isTimeTrackingEnabled: boolean | null, active: boolean | null, updatedAt: string | null, absences: Array<AbsenceDurationEntryFragment>, reports: Array<ReportDurationEntryFragment>, expenses: Array<{ uuid: string, createdAt: string, expenses: Array<{ uuid: string, deletedAt: string | null, note: string | null, createdAt: string, document: StoredDocumentFragment | null, expense: { value: number, currency: CurrencyEnum }, previousVersion: { uuid: string, deletedAt: string | null, note: string | null, createdAt: string, document: StoredDocumentFragment | null, expense: { value: number, currency: CurrencyEnum }, createdBy: { firstName: string, lastName: string, email: string } } | null, createdBy: { firstName: string, lastName: string, email: string } }> }>, statistics: { totalHours: number, usedHours: number | null, vacationDays: number, absences: number, expenses: Array<{ value: number, currency: CurrencyEnum }> } | null, days: Array<DayFragment>, company: { disableTimeTracking: boolean | null, contact: CompanyContactInformationFragment } | null, employee: { firstName: string, lastName: string } | null };

export type EmploymentForCompanyListItemFragment = (
  { personalNumber: number, hasDaysToApprove: boolean | null, daysToApproveCount: number | null, isTimeTrackingEnabled: boolean | null, employee: { phone: string | null, email: string, firstName: string, lastName: string, image: StoredDocumentWithDataFragment | null, address: { street: string, zip: string, location: string, country: string } | null } | null }
  & EmploymentSharedListItemFieldsFragment
);

export type EmploymentForEmployeeListItemFragment = (
  { isTimeTrackingEnabled: boolean | null, startDate: string, endDate: string | null }
  & EmploymentSharedListItemFieldsFragment
);

export type EmploymentSharedListItemFieldsFragment = { uuid: string, active: boolean | null, personalNumber: number, statistics: { totalHours: number, usedHours: number | null, vacationDays: number, absences: number, expenses: Array<{ value: number, currency: CurrencyEnum }> } | null, company: { disableTimeTracking: boolean | null, contact: { name: string, address: { street: string, zip: string, location: string, country: string } } } | null };

export type HoursEntryFragment = { uuid: string, deletedAt: string | null, from: number | null, to: number | null, duration: number, pause: number | null, note: string | null, createdAt: string, createdBy: { firstName: string, lastName: string, email: string }, previousVersions: Array<{ uuid: string, deletedAt: string | null, from: number | null, to: number | null, duration: number, pause: number | null, note: string | null, createdAt: string, createdBy: { firstName: string, lastName: string, email: string } }> };

export type ReportDurationEntryFragment = { createdAt: string, uuid: string, startDate: string, endDate: string, document: StoredDocumentFragment | null };

export type StoredDocumentFragment = { uuid: string, fileName: string, mimeType: string, type: DocumentTypeEnum, status: DocumentStatusEnum, createdAt: string | null, translatedDocumentId: string | null };

export type StoredDocumentWithDataFragment = (
  { data: string | null }
  & StoredDocumentFragment
);

export type UserFragment = { _id: string, staff: boolean | null, companyIds: Array<number> | null, personalNumber: number | null, responsibleForEmployees: Array<number> | null, lastSeenVersion: string | null, organisationUnit: { globalRoles: Array<GlobalRoleEnum> | null, customerUuid: string } | null, trackedActions: { hasSeenSamsungBenefit: boolean | null, hasSeenGalaxusBenefit: boolean | null } | null, profile: { language: SupportedLanguage | null, firstName: string, lastName: string, email: string, phone: string | null, position: string | null, translatedPayrollLanguage: string | null, storedDocuments: Array<StoredDocumentWithDataFragment> | null, image: StoredDocumentWithDataFragment | null, address: { location: string, zip: string, street: string, country: string } | null, bankingInformation: { iban: string, recipient: { firstName: string, lastName: string, address: { street: string, country: string, zip: string, location: string } } | null } | null } };

export type PreVerificationUserCheckMutationVariables = Exact<{
  checkUserVerificationInput: CheckUserVerificationInput;
}>;


export type PreVerificationUserCheckMutation = { preVerificationUserCheck: boolean };

export type RemoveProfileImageMutationVariables = Exact<{
  uuid: Scalars['String']['input'];
}>;


export type RemoveProfileImageMutation = { removeProfileImage: boolean };

export type RemoveUserDocumentMutationVariables = Exact<{
  uuid: Scalars['String']['input'];
}>;


export type RemoveUserDocumentMutation = { removeUserDocument: boolean };

export type ResendVerificationEmailMutationVariables = Exact<{ [key: string]: never; }>;


export type ResendVerificationEmailMutation = { resendVerificationEmail: boolean };

export type SendVerificationSmsMutationVariables = Exact<{
  checkUserVerificationInput: CheckUserVerificationInput;
}>;


export type SendVerificationSmsMutation = { sendVerificationSms: { phone: string, delay: number } };

export type UpdateDurationEntryMutationVariables = Exact<{
  data: DurationEntryInput;
}>;


export type UpdateDurationEntryMutation = { updateDurationEntry: boolean };

export type UpdateEmploymentMutationVariables = Exact<{
  data: EmploymentInput;
}>;


export type UpdateEmploymentMutation = { updateEmployment: { uuid: string } };

export type UpdateExpenseEntryMutationVariables = Exact<{
  data: ExpenseEntryInput;
}>;


export type UpdateExpenseEntryMutation = { updateExpenseEntry: boolean };

export type UpdateUserMutationVariables = Exact<{
  userInput: UserInput;
}>;


export type UpdateUserMutation = { updateUser: { profile: { firstName: string, lastName: string } } };

export type UploadProfileImageMutationVariables = Exact<{
  storedDocumentInput: StoredDocumentInput;
}>;


export type UploadProfileImageMutation = { uploadProfileImage: boolean };

export type UploadUserDocumentMutationVariables = Exact<{
  storedDocumentInput: StoredDocumentInput;
}>;


export type UploadUserDocumentMutation = { uploadUserDocument: boolean };

export type VerifyUserMutationVariables = Exact<{
  verifyUserInput: VerifyUserInput;
}>;


export type VerifyUserMutation = { verifyUser: boolean };

export type GetHoursExportQueryVariables = Exact<{
  types: Array<DayStatusEnum> | DayStatusEnum;
  to: Scalars['DateTime']['input'];
  from: Scalars['DateTime']['input'];
  personalNumbers: Array<Scalars['Float']['input']> | Scalars['Float']['input'];
}>;


export type GetHoursExportQuery = { getHoursExport: string };

export type DownloadUserDocumentQueryVariables = Exact<{
  uuid: Scalars['String']['input'];
}>;


export type DownloadUserDocumentQuery = { downloadUserDocument: (
    { createdAt: string | null, status: DocumentStatusEnum }
    & StoredDocumentWithDataFragment
  ) };

export type GetConsultantsQueryVariables = Exact<{ [key: string]: never; }>;


export type GetConsultantsQuery = { User: { _id: string, consultants: Array<ConsultantContactFragment> | null } };

export type GetDocumentsQueryVariables = Exact<{ [key: string]: never; }>;


export type GetDocumentsQuery = { User: { _id: string, storedDocuments: Array<(
      { createdAt: string | null, status: DocumentStatusEnum, translatedDocumentId: string | null }
      & StoredDocumentFragment
    )> | null } };

export type GetEmploymentsForCompanyQueryVariables = Exact<{ [key: string]: never; }>;


export type GetEmploymentsForCompanyQuery = { Employments: Array<EmploymentForCompanyListItemFragment> };

export type GetEmploymentsForEmployeeQueryVariables = Exact<{ [key: string]: never; }>;


export type GetEmploymentsForEmployeeQuery = { Employments: Array<EmploymentForEmployeeListItemFragment> };

export type GetSamsungBenefitQueryVariables = Exact<{ [key: string]: never; }>;


export type GetSamsungBenefitQuery = { getSamsungBenefit: string };

export type GetStoredDocumentQueryVariables = Exact<{
  uuid: Scalars['String']['input'];
}>;


export type GetStoredDocumentQuery = { getStoredDocument: StoredDocumentWithDataFragment | null };

export type GetUserQueryVariables = Exact<{ [key: string]: never; }>;


export type GetUserQuery = { User: UserFragment };

export type EmploymentSubscribeSubscriptionVariables = Exact<{
  uuid: Scalars['String']['input'];
}>;


export type EmploymentSubscribeSubscription = { employmentSubscribe: EmploymentFragment };

export const ConsultantContactFragmentDoc = gql`
    fragment ConsultantContact on ConsultantContact {
  name
  position
  email
  phone
  branch {
    name
    mailingAddress
    street
    zip
    location
    slug
  }
}
    `;
export const StoredDocumentFragmentDoc = gql`
    fragment StoredDocument on StoredDocument {
  uuid
  fileName
  mimeType
  type
  status
  createdAt
  translatedDocumentId
}
    `;
export const AbsenceDurationEntryFragmentDoc = gql`
    fragment AbsenceDurationEntry on DurationEntry {
  uuid
  startDate
  endDate
  kind
  note
  document {
    ...StoredDocument
  }
  createdAt
  createdBy {
    firstName
    lastName
    email
  }
  previousVersion {
    uuid
    startDate
    endDate
    kind
    note
    document {
      ...StoredDocument
    }
    createdAt
    createdBy {
      firstName
      lastName
      email
    }
  }
}
    `;
export const ReportDurationEntryFragmentDoc = gql`
    fragment ReportDurationEntry on DurationEntry {
  createdAt
  uuid
  startDate
  endDate
  document {
    ...StoredDocument
  }
}
    `;
export const HoursEntryFragmentDoc = gql`
    fragment HoursEntry on HoursEntry {
  uuid
  deletedAt
  from
  to
  duration
  pause
  note
  createdAt
  createdBy {
    firstName
    lastName
    email
  }
  previousVersions {
    uuid
    deletedAt
    from
    to
    duration
    pause
    note
    createdAt
    createdBy {
      firstName
      lastName
      email
    }
  }
}
    `;
export const DayFragmentDoc = gql`
    fragment Day on Day {
  absenceUUID
  expenseUUID
  reportUUID
  date
  status
  approvedAt
  approvedBy {
    profile {
      firstName
      lastName
    }
  }
  hoursEntries {
    ...HoursEntry
  }
}
    `;
export const CompanyContactInformationFragmentDoc = gql`
    fragment CompanyContactInformation on CompanyContactInformation {
  name
  address {
    street
    zip
    location
    country
  }
}
    `;
export const EmploymentFragmentDoc = gql`
    fragment Employment on Employment {
  uuid
  startDate
  endDate
  isTimeTrackingEnabled
  active
  updatedAt
  absences {
    ...AbsenceDurationEntry
  }
  reports {
    ...ReportDurationEntry
  }
  expenses {
    uuid
    createdAt
    expenses {
      uuid
      deletedAt
      note
      document {
        ...StoredDocument
      }
      expense {
        value
        currency
      }
      note
      createdAt
      previousVersion {
        uuid
        deletedAt
        note
        document {
          ...StoredDocument
        }
        expense {
          value
          currency
        }
        note
        createdAt
        createdBy {
          firstName
          lastName
          email
        }
      }
      createdBy {
        firstName
        lastName
        email
      }
    }
  }
  statistics {
    totalHours
    usedHours
    vacationDays
    absences
    expenses {
      value
      currency
    }
  }
  days {
    ...Day
  }
  company {
    disableTimeTracking
    contact {
      ...CompanyContactInformation
    }
  }
  employee {
    firstName
    lastName
  }
}
    `;
export const EmploymentSharedListItemFieldsFragmentDoc = gql`
    fragment EmploymentSharedListItemFields on Employment {
  uuid
  active
  personalNumber
  statistics {
    totalHours
    usedHours
    vacationDays
    absences
    expenses {
      value
      currency
    }
  }
  company {
    disableTimeTracking
    contact {
      name
      address {
        street
        zip
        location
        country
      }
    }
  }
}
    `;
export const StoredDocumentWithDataFragmentDoc = gql`
    fragment StoredDocumentWithData on StoredDocument {
  ...StoredDocument
  data
}
    `;
export const EmploymentForCompanyListItemFragmentDoc = gql`
    fragment EmploymentForCompanyListItem on Employment {
  ...EmploymentSharedListItemFields
  personalNumber
  hasDaysToApprove
  daysToApproveCount
  isTimeTrackingEnabled
  employee {
    phone
    email
    firstName
    image {
      ...StoredDocumentWithData
    }
    lastName
    address {
      street
      zip
      location
      country
    }
  }
}
    `;
export const EmploymentForEmployeeListItemFragmentDoc = gql`
    fragment EmploymentForEmployeeListItem on Employment {
  ...EmploymentSharedListItemFields
  isTimeTrackingEnabled
  startDate
  endDate
}
    `;
export const UserFragmentDoc = gql`
    fragment User on User {
  _id
  staff
  companyIds
  personalNumber
  responsibleForEmployees
  organisationUnit {
    globalRoles
    customerUuid
  }
  trackedActions {
    hasSeenSamsungBenefit
    hasSeenGalaxusBenefit
  }
  profile {
    language
    storedDocuments {
      ...StoredDocumentWithData
    }
    firstName
    lastName
    email
    phone
    position
    image {
      ...StoredDocumentWithData
    }
    address {
      location
      zip
      street
      country
    }
    bankingInformation {
      iban
      recipient {
        firstName
        lastName
        address {
          street
          country
          zip
          location
        }
      }
    }
    translatedPayrollLanguage
  }
  lastSeenVersion
}
    `;
export const PreVerificationUserCheckDocument = gql`
    mutation preVerificationUserCheck($checkUserVerificationInput: CheckUserVerificationInput!) {
  preVerificationUserCheck(
    CheckUserVerificationInput: $checkUserVerificationInput
  )
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class PreVerificationUserCheckGQL extends Apollo.Mutation<PreVerificationUserCheckMutation, PreVerificationUserCheckMutationVariables> {
    document = PreVerificationUserCheckDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const RemoveProfileImageDocument = gql`
    mutation removeProfileImage($uuid: String!) {
  removeProfileImage(uuid: $uuid)
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class RemoveProfileImageGQL extends Apollo.Mutation<RemoveProfileImageMutation, RemoveProfileImageMutationVariables> {
    document = RemoveProfileImageDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const RemoveUserDocumentDocument = gql`
    mutation removeUserDocument($uuid: String!) {
  removeUserDocument(uuid: $uuid)
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class RemoveUserDocumentGQL extends Apollo.Mutation<RemoveUserDocumentMutation, RemoveUserDocumentMutationVariables> {
    document = RemoveUserDocumentDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const ResendVerificationEmailDocument = gql`
    mutation resendVerificationEmail {
  resendVerificationEmail
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class ResendVerificationEmailGQL extends Apollo.Mutation<ResendVerificationEmailMutation, ResendVerificationEmailMutationVariables> {
    document = ResendVerificationEmailDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const SendVerificationSmsDocument = gql`
    mutation sendVerificationSms($checkUserVerificationInput: CheckUserVerificationInput!) {
  sendVerificationSms(CheckUserVerificationInput: $checkUserVerificationInput) {
    phone
    delay
  }
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class SendVerificationSmsGQL extends Apollo.Mutation<SendVerificationSmsMutation, SendVerificationSmsMutationVariables> {
    document = SendVerificationSmsDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const UpdateDurationEntryDocument = gql`
    mutation updateDurationEntry($data: DurationEntryInput!) {
  updateDurationEntry(data: $data)
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class UpdateDurationEntryGQL extends Apollo.Mutation<UpdateDurationEntryMutation, UpdateDurationEntryMutationVariables> {
    document = UpdateDurationEntryDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const UpdateEmploymentDocument = gql`
    mutation updateEmployment($data: EmploymentInput!) {
  updateEmployment(data: $data) {
    uuid
  }
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class UpdateEmploymentGQL extends Apollo.Mutation<UpdateEmploymentMutation, UpdateEmploymentMutationVariables> {
    document = UpdateEmploymentDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const UpdateExpenseEntryDocument = gql`
    mutation updateExpenseEntry($data: ExpenseEntryInput!) {
  updateExpenseEntry(data: $data)
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class UpdateExpenseEntryGQL extends Apollo.Mutation<UpdateExpenseEntryMutation, UpdateExpenseEntryMutationVariables> {
    document = UpdateExpenseEntryDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const UpdateUserDocument = gql`
    mutation updateUser($userInput: UserInput!) {
  updateUser(UserInput: $userInput) {
    profile {
      firstName
      lastName
    }
  }
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class UpdateUserGQL extends Apollo.Mutation<UpdateUserMutation, UpdateUserMutationVariables> {
    document = UpdateUserDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const UploadProfileImageDocument = gql`
    mutation uploadProfileImage($storedDocumentInput: StoredDocumentInput!) {
  uploadProfileImage(StoredDocumentInput: $storedDocumentInput)
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class UploadProfileImageGQL extends Apollo.Mutation<UploadProfileImageMutation, UploadProfileImageMutationVariables> {
    document = UploadProfileImageDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const UploadUserDocumentDocument = gql`
    mutation uploadUserDocument($storedDocumentInput: StoredDocumentInput!) {
  uploadUserDocument(StoredDocumentInput: $storedDocumentInput)
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class UploadUserDocumentGQL extends Apollo.Mutation<UploadUserDocumentMutation, UploadUserDocumentMutationVariables> {
    document = UploadUserDocumentDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const VerifyUserDocument = gql`
    mutation verifyUser($verifyUserInput: VerifyUserInput!) {
  verifyUser(VerifyUserInput: $verifyUserInput)
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class VerifyUserGQL extends Apollo.Mutation<VerifyUserMutation, VerifyUserMutationVariables> {
    document = VerifyUserDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const GetHoursExportDocument = gql`
    query GetHoursExport($types: [DayStatusEnum!]!, $to: DateTime!, $from: DateTime!, $personalNumbers: [Float!]!) {
  getHoursExport(
    types: $types
    to: $to
    from: $from
    personalNumbers: $personalNumbers
  )
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class GetHoursExportGQL extends Apollo.Query<GetHoursExportQuery, GetHoursExportQueryVariables> {
    document = GetHoursExportDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const DownloadUserDocumentDocument = gql`
    query downloadUserDocument($uuid: String!) {
  downloadUserDocument(uuid: $uuid) {
    ...StoredDocumentWithData
    createdAt
    status
  }
}
    ${StoredDocumentWithDataFragmentDoc}
${StoredDocumentFragmentDoc}`;

  @Injectable({
    providedIn: 'root'
  })
  export class DownloadUserDocumentGQL extends Apollo.Query<DownloadUserDocumentQuery, DownloadUserDocumentQueryVariables> {
    document = DownloadUserDocumentDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const GetConsultantsDocument = gql`
    query getConsultants {
  User {
    _id
    consultants {
      ...ConsultantContact
    }
  }
}
    ${ConsultantContactFragmentDoc}`;

  @Injectable({
    providedIn: 'root'
  })
  export class GetConsultantsGQL extends Apollo.Query<GetConsultantsQuery, GetConsultantsQueryVariables> {
    document = GetConsultantsDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const GetDocumentsDocument = gql`
    query getDocuments {
  User {
    _id
    storedDocuments {
      ...StoredDocument
      createdAt
      status
      translatedDocumentId
    }
  }
}
    ${StoredDocumentFragmentDoc}`;

  @Injectable({
    providedIn: 'root'
  })
  export class GetDocumentsGQL extends Apollo.Query<GetDocumentsQuery, GetDocumentsQueryVariables> {
    document = GetDocumentsDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const GetEmploymentsForCompanyDocument = gql`
    query getEmploymentsForCompany {
  Employments {
    ...EmploymentForCompanyListItem
  }
}
    ${EmploymentForCompanyListItemFragmentDoc}
${EmploymentSharedListItemFieldsFragmentDoc}
${StoredDocumentWithDataFragmentDoc}
${StoredDocumentFragmentDoc}`;

  @Injectable({
    providedIn: 'root'
  })
  export class GetEmploymentsForCompanyGQL extends Apollo.Query<GetEmploymentsForCompanyQuery, GetEmploymentsForCompanyQueryVariables> {
    document = GetEmploymentsForCompanyDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const GetEmploymentsForEmployeeDocument = gql`
    query getEmploymentsForEmployee {
  Employments {
    ...EmploymentForEmployeeListItem
  }
}
    ${EmploymentForEmployeeListItemFragmentDoc}
${EmploymentSharedListItemFieldsFragmentDoc}`;

  @Injectable({
    providedIn: 'root'
  })
  export class GetEmploymentsForEmployeeGQL extends Apollo.Query<GetEmploymentsForEmployeeQuery, GetEmploymentsForEmployeeQueryVariables> {
    document = GetEmploymentsForEmployeeDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const GetSamsungBenefitDocument = gql`
    query getSamsungBenefit {
  getSamsungBenefit
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class GetSamsungBenefitGQL extends Apollo.Query<GetSamsungBenefitQuery, GetSamsungBenefitQueryVariables> {
    document = GetSamsungBenefitDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const GetStoredDocumentDocument = gql`
    query getStoredDocument($uuid: String!) {
  getStoredDocument(uuid: $uuid) {
    ...StoredDocumentWithData
  }
}
    ${StoredDocumentWithDataFragmentDoc}
${StoredDocumentFragmentDoc}`;

  @Injectable({
    providedIn: 'root'
  })
  export class GetStoredDocumentGQL extends Apollo.Query<GetStoredDocumentQuery, GetStoredDocumentQueryVariables> {
    document = GetStoredDocumentDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const GetUserDocument = gql`
    query getUser {
  User {
    ...User
  }
}
    ${UserFragmentDoc}
${StoredDocumentWithDataFragmentDoc}
${StoredDocumentFragmentDoc}`;

  @Injectable({
    providedIn: 'root'
  })
  export class GetUserGQL extends Apollo.Query<GetUserQuery, GetUserQueryVariables> {
    document = GetUserDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const EmploymentSubscribeDocument = gql`
    subscription EmploymentSubscribe($uuid: String!) {
  employmentSubscribe(uuid: $uuid) {
    ...Employment
  }
}
    ${EmploymentFragmentDoc}
${AbsenceDurationEntryFragmentDoc}
${StoredDocumentFragmentDoc}
${ReportDurationEntryFragmentDoc}
${DayFragmentDoc}
${HoursEntryFragmentDoc}
${CompanyContactInformationFragmentDoc}`;

  @Injectable({
    providedIn: 'root'
  })
  export class EmploymentSubscribeGQL extends Apollo.Subscription<EmploymentSubscribeSubscription, EmploymentSubscribeSubscriptionVariables> {
    document = EmploymentSubscribeDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }