// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.
import { Environment } from './environment-type';
/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
import 'zone.js';
import 'zone.js/testing';

let localEnvironment = {};
try {
  localEnvironment = require('./environment.local.js').localEnvironment;
} catch (e) {
  console.log(
    'No /src/environments/environment.local.js file Found. Loading default environment.',
  );
}
export const environment: Environment = Object.assign(
  {
    environment: 'DEV',
    production: false,
    auth0ApiUrl: 'authdev.universaljob.ch',
    auth0ClientId: 's0xahXX915J0L6ShrwN8j90ZXv6vgz1S',
    audience: 'https://dev.api.my.universaljob.ch',
    //gqlApiXUrl: 'https://dev2.api.universaljob.ch/graphql',
    gqlApiXUrl: 'https://dev.api.my.universaljob.ch/graphql',
    gqlApiXWsUrl: 'wss://dev.api.my.universaljob.ch/graphql',
    universaljobHost: 'https://dev.universaljob.ch',
    ownHost: 'https://dev.my.universaljob.ch',
  } satisfies Environment,
  localEnvironment,
);
