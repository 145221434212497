import {
  AfterViewInit,
  Component,
  EventEmitter,
  HostBinding,
  Input,
  OnDestroy,
  OnInit,
  Output,
} from '@angular/core';
import {
  CanLoadPages,
  UserService,
} from '../../models/shared/user/user.service';
import { Subject } from 'rxjs';
import { DocumentService } from '../../models/shared/document/document.service';
import { UserInput } from 'src/app/graphql/generated';
import { Router } from '@angular/router';
import { CompanyEmploymentService } from '../../models/uniweb/employment/company-employment.service';
import { DocumentEventListenerService } from '@intemp/unijob-ui';
import { UnreadDocumentsState } from '../../models/shared/document/UnreadDocumentsState';
import { takeUntil } from 'rxjs/operators';
import { BuildInfo, BuildInfoService } from '../services/build-infos.service';
import { UserFragment } from 'src/app/graphql/generated';

export type NavCollapsedClass = 'expanded' | 'collapsed';

@Component({
  selector: 'app-nav',
  templateUrl: './nav.component.html',
  styleUrls: ['./nav.component.scss'],
})
export class NavComponent implements OnInit, OnDestroy, AfterViewInit {
  destroyed$ = new Subject<void>();
  navCollapsed = false;
  navBreakpoint = 992;
  docStatus: UnreadDocumentsState = { unread: {}, read: {} };
  canLoadPages?: CanLoadPages;

  @Input({ required: true }) navToggle!: EventEmitter<void>;
  @Output() navToggled = new EventEmitter<boolean>();

  @HostBinding('class') navCollapsedClass: NavCollapsedClass = 'expanded';

  hasUnseenBenefits = false;

  constructor(
    public userService: UserService,
    public router: Router,
    private documentService: DocumentService,
    private documentEventListenerService: DocumentEventListenerService,
    public companyService: CompanyEmploymentService,
    private buildInfoService: BuildInfoService,
  ) {}

  get isMobile(): boolean {
    return window.innerWidth < this.navBreakpoint;
  }

  public buildInfo: BuildInfo = {
    commit: '',
    tag: '',
    branch: '',
    env: '',
  };
  get mainBuildInfo(): string {
    if (this.buildInfo.tag !== 'no tag') {
      return this.buildInfo.tag;
    }
    if (this.buildInfo.commit) {
      return this.buildInfo.commit;
    }
    if (this.buildInfo.branch) {
      return this.buildInfo.branch;
    }
    return '?';
  }

  async ngOnInit(): Promise<void> {
    this.canLoadPages = await this.userService.getLoadablePages();
    this.documentService.documentCountObservable.subscribe((docStatus) => {
      this.docStatus = docStatus;
    });
    this.navCollapsed = this.isMobile;
    this.buildInfoService.getBuildInfo().subscribe((info) => {
      this.buildInfo = Object.assign(this.buildInfo, info);
    });
    this.updateNavCollapsedClass();

    this.navToggle.pipe(takeUntil(this.destroyed$)).subscribe(() => {
      this.toggleNavCollapsed();
    });
    this.closeMobileNav();
    this.checkWindowSize();
    const user = await this.userService.getUser();
    if (user.companyIds?.length) {
      this.companyService.getEmployments$().subscribe((employments) => {
        const hasDaysToApprove = employments.some(
          (employment) => employment.active && employment.hasDaysToApprove,
        );
        this.companyService.hasDaysToApprove.next(hasDaysToApprove);
      });
    }
    // check list
    this.userService.readyUser$.subscribe((user: UserFragment) => {
      if (user?.trackedActions) {
        this.hasUnseenBenefits = Object.values(user.trackedActions).some(
          (value) => !value,
        );
      } else if (!user.trackedActions && this.canLoadPages?.BENEFITS) {
        this.hasUnseenBenefits = true;
      }
    });
  }

  ngAfterViewInit(): void {
    this.documentEventListenerService.debouncedResizeEmitter
      .pipe(takeUntil(this.destroyed$))
      .subscribe(() => {
        this.checkWindowSize();
        this.enableScroll();
      });
  }

  closeMobileNav(): void {
    if (this.isMobile) {
      this.toggleNavCollapsed(true);
      (document.activeElement as HTMLElement).blur();
    }
  }

  updateUser(): void {
    const userInput: UserInput = {
      trackedActions: {
        hasSeenSamsungBenefit: true,
        hasSeenGalaxusBenefit: true,
        hasSeenKieserBenefit: true,
        hasSeenCarifyBenefit: true,
        hasSeenFitpassBenefit: true,
      },
    };
    const user = this.userService.user$.value;
    if (user && this.hasUnseenBenefits) {
      this.userService.updateUser$(userInput).subscribe();
    }
  }

  toggleNavCollapsed(collapsed?: boolean): void {
    if (collapsed) {
      this.navCollapsed = collapsed;
    } else {
      this.navCollapsed = !this.navCollapsed;
    }
    if (this.isMobile) {
      this.toggleScrollLock();
    }
    this.updateNavCollapsedClass();
  }

  toggleScrollLock(): void {
    if (this.navCollapsed) {
      this.enableScroll();
    } else {
      this.disableScroll();
    }
  }

  checkWindowSize(): void {
    this.navCollapsed = window.innerWidth < this.navBreakpoint;
    this.updateNavCollapsedClass();
  }

  updateNavCollapsedClass(): void {
    this.navCollapsedClass = this.navCollapsed ? 'collapsed' : 'expanded';
    this.navToggled.emit(this.navCollapsed);
  }

  disableScroll(): void {
    document.body.classList.add('scroll-lock');
    document.ontouchmove = (e) => e.preventDefault();
  }

  enableScroll(): void {
    document.body.classList.remove('scroll-lock');
    document.ontouchmove = () => true;
  }

  ngOnDestroy(): void {
    this.destroyed$.next();
    this.destroyed$.complete();
  }
}
